import React from 'react';
import { Link } from "react-router-dom";
import home_photo from "../images/Diverticulitis_home_pg_pic-logo_5.png";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import { useLocation } from 'react-router-dom';

function Header({show_image=true}) {
  let location = useLocation();
  let {pathname} = location;

  return (
      <div className="banner hide-from-print fixed-top">
        <Container>
            <Row>
                <Navbar className="header-nav justify-content-center">
                    { show_image == true &&
                        <Link className="img-transparent" to={"/"}><img src={home_photo} /></Link>
                    }

                    { show_image != true &&
                        <Link className="img-transparent limit-opacity" to={"/"}><img src={home_photo} /></Link>
                    }
                    <Link className="header-link text-wrap" to={"/treatment-options"}><Button className={pathname == "/treatment-options" ? "header-selected-button" : ""}>{pathname == "/treatment-options" ? "Treatment Options" : "What are your treatment options?"}</Button></Link>
                    <Link className="header-link text-wrap" to={"/activities"}><Button className={pathname == "/activities" ? "header-selected-button" : ""}>{pathname == "/activities" ? "Important Activities" : "What activites are most important to you?"}</Button></Link>
                    <Link className="header-link text-wrap" to={"/faq"}><Button className={pathname == "/faq" ? "header-selected-button" : ""}>{pathname == "/faq" ? "FAQ" : "Frequently Asked Questions"}</Button></Link>
                </Navbar>
            </Row>
        </Container>
      </div>
  );
}

export default Header;
