import React from 'react';
import { Link } from "react-router-dom";
import home_photo from "../images/Diverticulitis_home_pg_pic-logo_5.png";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import VumcLogo from '../images/vumc_logo.png';
import Col from 'react-bootstrap/Col';

function Footer() {
  return (
      <footer className="hide-from-print">
        <Container>
            <Row className='align-items-center justify-content-between'>
              <Col className="col-4">
                <p>Copyright ©2024 by Vanderbilt University Medical Center</p>
              </Col>
              <Col className="col-2">
                <p>
                  <Link to={"/"}><img className="logo" src={VumcLogo} /></Link>
                </p>
              </Col>
            </Row>
        </Container>
      </footer>
  );
}

export default Footer;
