import Markdown from 'react-markdown'
import Header from "./components/Header";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from "./components/Footer";

import home_photo from "./images/Diverticulitis_home_pg_pic-logo_5.png";
import colon_photo from "./images/colon_with_diverticula_540.jpg";

function App() {
  return (
    <div className="App main-page">
      <Container>
        <Header show_image="false" />

        <Row className="content-start justify-content-center">
          <img src={home_photo} />
        </Row>

        <Row className="justify-content-start">
            <Markdown>
              This site is meant for people diagnosed with diverticulitis without complications (fistula, stricture, etc.) **to explore treatment options.**
            </Markdown>

            <Markdown>
              Some of these options may not be available if you are experiencing more severe symptoms or complications. These treatments might not help if your symptoms are from other medical problems, such as inflammatory bowel disease. Talk to your doctor if you are not sure.
            </Markdown>

            <Markdown>
              Nothing on this website is intended to be for medical diagnosis or treatment. Any individual should consult with your physician before starting any new treatment with questions or concerns.
            </Markdown>
        </Row>

        <Row className="mb-3">
          <Col className="bg-gray">
            <Markdown className="heading-green">
              ## What is Diverticulitis?
            </Markdown>

            <Markdown>
              Diverticulitis is inflammation in the small pouches (diverticula) of your colon, or large bowel. This may be the first time you have heard of this condition, but more than 200,000 people in the United States are hospitalized each year. People have different symptoms. The most common symptoms are pain, diarrhea, constipation, and fevers. Some people have multiple episodes of diverticulitis. This is called recurrent diverticulitis.
            </Markdown>

            <Markdown>
              There are two treatment choices for recurrent diverticulitis:
            </Markdown>

            <Markdown>
              - Some people do well with medication and diet changes. 
            </Markdown>

            <Markdown>
              - Others may do better with surgery.
            </Markdown>
            
            <Markdown>
              This website will give you information about exploring your options. This will help you make the best choice.
            </Markdown>

            <Markdown>
              **Think about what is important to you about your treatment.**
            </Markdown>
          </Col>

          <Col>
            <img src={colon_photo} />
          </Col>
        </Row>

        <div style={{marginTop: "5rem"}}>
         <Header show_image="false" />
        </div>

        <p>
          This work was supported by the National Institute of Diabetes and Digestive and Kidney Disease of the National Institutes of Health under award number R03DK129559. The content is solely the responsibility of the authors and does not necessarily represent the official views of the National Institutes of Health. This decision aid was developed by the Section of Colon and Rectal Surgery at Vanderbilt University Medical Center. The developers of this decision aid have no conflicts of interest to report.
        </p>
      </Container>
    <Footer />
  </div>
  );
}

export default App;
