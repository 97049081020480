import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import Markdown from 'react-markdown'
import Header from "./components/Header";
import Footer from "./components/Footer";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import ActivityInteraction from './components/ActivityInteraction';

function Activities() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function valuetext(value) {
    return `${value}°C`;
  }

  const marks = [
    {
      value: 1,
      label: 'Surgery',
    },
    {
      value: 25,
      label: 'Maybe Surgery',
    },
    {
      value: 50,
      label: 'Unsure',
    },
    {
      value: 75,
      label: 'Maybe Medicine',
    },
    {
      value: 100,
      label: 'Medicine',
    }
  ];

  return (
    <div className="App activity">
      <Container>
        <div ref={componentRef}>
          <Header />
          <Row className="content-start justify-content-center">
            <Markdown className="heading-green text-center hide-from-print">
              ## What is most important to you?
            </Markdown>

            <Markdown className="hide-from-print">
              Let’s see what is most important to you! You can take a printed copy of this page with you to your doctor. It will help your doctor figure out what treatment may be best for you.
            </Markdown>

            <Markdown>
              Pick the **five activities** from the list below that are most important in your life, click and **drag them to the list on the right**, and rank them in order from most important (1) to least important (5). You can also add activities of your own.
            </Markdown>
          </Row>
        
          <ActivityInteraction />

          <Row className="textarea-row">
            <Markdown className="heading-green">#### Think about your list.</Markdown>
            <Markdown>Now, answer the following questions. These will be helpful when talking to your doctor.</Markdown>

            <Markdown className="heading-green">#### What scares you most about diverticulitis?</Markdown>
            <textarea placeholder="Enter text here..."></textarea>

            <Markdown className="heading-green">#### What matters most to you about treatment?</Markdown>
            <textarea placeholder="Enter text here..."></textarea>

            <Markdown className="heading-green">#### What would you choose now?</Markdown>

            <Markdown>Slide the green triangle under your answer.</Markdown>

            <Box className="mb-3" sx={{ width: "80%", margin: "0 auto"}}>
              <Slider
               aria-label="Custom marks"
               defaultValue={50}
               getAriaValueText={valuetext}
               step={null} 
               valueLabelDisplay="off"
               marks={marks}
               sx={{
                  marginTop: "30px",
                  marginBottom: "30px",
                  '& .MuiSlider-rail': {
                    color: "#EEEEEE !important",
                    top: "35px !important",
                    lineHeight: "15px"
                  },
                  '& .MuiSlider-track': {
                    color: "transparent",
                  },
                  '& .Mui-active, & .MuiSlider-thumb:hover': {
                    boxShadow: "none !important"
                  },
                  '& .MuiSlider-markLabel': {
                    fontSize: "16px !important",
                    top: "-30px !important"
                  },
                  '& .MuiSlider-thumb': {
                    width: 0,
                    height: 0,
                    backgroundColor: "transparent",
                    border: "35px solid transparent",
                    borderTop: "0m",
                    borderBottom: "75px solid rgb(16,176,52)",
                    borderRadius: "0 !important"
                  },
                 }}
              />
            </Box>

            <Markdown className="heading-green">#### Great job! These questions can be hard to think about. Don't forget to print this page to take with you to your visit.</Markdown>

            <Col className="hide-from-print text-center print-button">
              <Button onClick={handlePrint}>Print Page</Button>
            </Col>
          </Row>
        </div>
      </Container>
      <Footer />
  </div>
  );
}

export default Activities;
