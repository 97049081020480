import React, { Component, useState, useRef } from 'react';
import { Container, Draggable } from '@edorivai/react-smooth-dnd';
import { applyDrag } from './utils';
const groupStyle = {
  marginLeft: '50px'
};

export default function Groups() {   
    const [customItem1, setCustom1] = useState("Add an activity 1");
    const [customItem2, setCustom2] = useState("Add an activity 2");

    const [items1, setItems1] = useState([
        {
            id: 1,
            data: "Traveling"
        },
        {
            id: 2,
            data: "Fewer bathroom breaks or bathroom accidents"
        },
        {
            id: 3,
            data: "Avoiding pain"
        },
        {
            id: 4,
            data: "Avoiding hospitalization"
        },
        {
            id: 5,
            data: "Being outdoors"
        },
        {
            id: 6,
            data: "Exercising"
        },
        {
            id: 7,
            data: "Working"
        },
        {
            id: 8,
            data: "Eating the foods you want"
        },
        {
            id: 9,
            data: "Watching TV"
        },
        {
            id: 10,
            data: "Avoiding antibiotics"
        },
        {
            id: 11,
            data: "custom1",
            element: "input"
        },
        {
            id: 12,
            data: "custom2",
            element: "input"
        }
      ]);

    const [items2, setItems2] = useState([
    ]);
  
    return (
      <div style={{ display: 'flex', marginTop: '50px', marginRight: '50px', justifyContent: 'center' }}>
        <div className="lighter-container" style={groupStyle}>
          <h4 className="heading-green">From these activities...</h4>

          <Container dragClass="opacity-ghost" dropClass="opacity-ghost-drop" groupName="1" getChildPayload={i => items1[i]} onDrop={e => setItems1(applyDrag(items1, e))}>
            {
              items1.map((p, index) => {
                return (
                  <Draggable key={p.id}>
                    <div className="draggable-item">
                    {p.element ? (
                      <>
                        {p.data == "custom1" ? (
                          <>
                            <textarea defaultValue={customItem1} onChange={(e) => setCustom1(e.target.value)}></textarea>
                          </>
                        ) : (
                          <>
                            <textarea defaultValue={customItem2} onChange={(e) => setCustom2(e.target.value)}></textarea>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                         {p.data}
                      </>
                    )}
                    </div>
                  </Draggable>
                  
                );
              })
            }
          </Container>
        </div>

        <div class="arrow-icon"><svg class="bi bi-arrow-right" fill="currentColor" height="64" viewBox="0 0 16 16" width="64" xmlns="http://www.w3.org/2000/svg"> <path d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" fill-rule="evenodd"></path> </svg></div>
        
        <div className="darker-container">
          <h4 className="heading-green">...rank your top 5</h4>
          
          <Container dragClass="opacity-ghost" dropClass="opacity-ghost-drop" groupName="1" getChildPayload={i => items2[i]} onDrop={e => setItems2(applyDrag(items2, e))}>
            { items2.length < 1 && (
              <p className="default-activity-item">Drag activities here</p>
            )}

            {
              items2.map((p, index) => {
                return (
                  <Draggable key={p.id}>
                    <div className="draggable-item">
                     {p.element ? (
                      <>
                        {p.data == "custom1" ? (
                          <>
                            &nbsp; {index+1}: <textarea defaultValue={customItem1} onChange={(e) => setCustom1(e.target.value)}></textarea>
                          </>
                        ) : (
                          <>
                            &nbsp; {index+1}: <textarea defaultValue={customItem2} onChange={(e) => setCustom2(e.target.value)}></textarea>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                         &nbsp; {index+1}: {p.data}
                      </>
                    )}
                    </div>
                  </Draggable>
                );
              })
            }
          </Container>
        </div>
      </div>
    );
}