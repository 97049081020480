import Markdown from 'react-markdown'
import Header from "./components/Header";
import Footer from "./components/Footer";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Faq() {
  return (
    <div className="App">
      <Container>
        <Header />

        <Row className="content-start justify-content-center">
          <Markdown className="heading-green">
            #### Diverticulitis: Q&A
          </Markdown>
        </Row>

        <Row className="justify-content-start">
            <Markdown className="faq-border">**Q: What is an ostomy bag and will I need one?**</Markdown>

            <Markdown>
                A: An ostomy is where the surgeon brings the end of the bowel to a new surgical opening through the abdominal wall. The patient then wears a removable appliance to collect the bowel contents. Both surgery and medical therapy have a low risk of needing an ostomy.  For surgery, the risk comes from complications of surgery.  For medical therapy, the risk comes from a severe episode that requires emergency surgery. For more information on ostomies, please visit the United Ostomy Association of America.            
            </Markdown>

            <Markdown className="faq-border">
                **Q: Can I eat what I want?**
            </Markdown>

            <Markdown>
                A: The best medical studies say yes, you can eat what you want. The foods you eat do not cause episodes. This includes things such as popcorn, nuts, seeds and strawberries.  As long as you have eaten it before without any problems, you should feel good that it will not bring on an episode.  
            </Markdown>

            <Markdown className="faq-border">
                **Q: Will I have more episodes?**
            </Markdown>

            <Markdown>
                A: Successful surgery can prevent future episodes and decrease symptoms. Medical therapy can also help to reduce episodes and symptoms. However, it is difficult to say how many episodes you will have. Everyone is different. Talk to your doctor about what is best for you.
            </Markdown>

            <Markdown className="faq-border">
                **Q: If I have surgery, how much of my colon will be removed?**
            </Markdown>

            <Markdown>
                A: This depends on how bad your diverticulitis is. This is something your surgeon will discuss with you at your visit.
            </Markdown>

            <Markdown className="faq-border">
                **Q: Will I still need to take antibiotics for diverticulitis if I have surgery?**
            </Markdown>

            <Markdown>
                A: Antibiotics are usually related to episodes of diverticulitis. It is unlikely that you will need to take antibiotics after recovering from surgery. You may need to take antibiotics depending on how bad your future episodes you may have.
            </Markdown>     

            <Markdown className="faq-border">
                **Q: Who can I contact with questions about this website?**
            </Markdown>

            <p>
                A: Contact Alexander T. Hawkins MD MPH at <a href="mailto:alex.hawkins@vumc.org" target="_blank">alex.hawkins@vumc.org</a>
            </p>        
        </Row>

      </Container>
      <Footer />
  </div>
  );
}

export default Faq;
