import Markdown from 'react-markdown'

import Header from "./components/Header";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Footer from "./components/Footer";

import colon_photo from "./images/colon_before_after_trns,1400_4 (1).png";
import table_photo from "./images/medicine_table_content_trns,1400_3 (1).png";
import { Link } from "react-router-dom";

function TreatmentOptions() {
  const surgeonList = `
  - Your surgeon will remove the section of your colon that has the diverticulitis.
  - Most of the time, your surgeon will be able to reconnect your colon so you can have normal bowel movements.
  - The scar depends on the cut that the surgeon will make.
  - You will need to plan both for time in the hospital and recovery at home.
  `;

  const riskList = `
  - Chance of ostomy.
  - Possible complications from surgery.
  - Time in hospital and time for recovery.    
  `;

  const riskList2 = `
  - Chance of ostomy.
  - Diverticulitis can come back.
  - Chance of emergency surgery.
  `;

  const benefitList = `
  - Prevent further episodes and antibiotics.
  - Less worry about future episodes.
  `;

  const benefitList2 = `
  - Avoid risks of surgery.
  - No surgical recovery.
  `;

  return (
    <div className="App">     
      <Container>
       <Header />

        <Row className="content-start justify-content-center">
          <Markdown className="heading-green text-center">
            ## There are options. You have a choice!
          </Markdown>
        </Row>

        <Row className="justify-content-start mb-3">
            <Markdown>
              The information presented here is meant to help start the conversation with your surgeon. Please discuss with your surgeon for more details.
            </Markdown>
        </Row>

        <Row className="no-padding">
          <Col xs={2} className="text-end">
            <Markdown className="heading-with-bottom-border header-with-padding"></Markdown>
            <Markdown>
                ## WHAT
            </Markdown>
          </Col>
          <Col id="sb1" className="space-back body-background-warning">
            <Markdown className="heading-with-bottom-border heading-warning text-center">
              ## SURGERY
            </Markdown>

            <Markdown className="nudge-p">
                **A procedure to remove the part of the colon that has the diverticulitis. Can be called a “colectomy” or a “sigmoidectomy.”**
            </Markdown>

            <img className="table-photo" src={colon_photo} />

            <Markdown>{surgeonList}</Markdown>
            
          </Col>

          <Col className="body-background-primary">
            <Markdown className="heading-with-bottom-border heading-primary text-center">
              ## MEDICAL
            </Markdown>

            <Markdown className="nudge-p">
                **A mix of nutrition, medication and behavioral changes to decrease your chance of having future episodes.**
            </Markdown>

            <img className="table-photo" src={table_photo} />
          </Col>
        </Row>

        <Row className="no-padding">
          <Col  xs={2} className="text-end">
            <Markdown className="heading-with-bottom-border"></Markdown>
            <Markdown>
                ## HOSPITAL
            </Markdown>
          </Col>
          <Col id="sb2" className="space-back body-background-warning">
             <Markdown className="heading-with-bottom-border"></Markdown>

            <Markdown>- You will stay in the hospital after surgery.</Markdown>
          </Col>

          <Col className="body-background-primary">
            <Markdown className="heading-with-bottom-border"></Markdown>

            <Markdown>- You may need to seek medical care with future episodes.</Markdown>
          </Col>
        </Row>

        <Row className="no-padding">
          <Col  xs={2} className="text-end">
            <Markdown className="heading-with-bottom-border"></Markdown>
            <Markdown>
                ## RISKS
            </Markdown>
          </Col>
          <Col id="sb3" className="space-back body-background-warning">
             <Markdown className="heading-with-bottom-border"></Markdown>

            <Markdown>{riskList}</Markdown>  
          </Col>

          <Col className="body-background-primary">
            <Markdown className="heading-with-bottom-border"></Markdown>

            <Markdown>{riskList2}</Markdown>
          </Col>
        </Row>

        <Row className="no-padding">
          <Col  xs={2} className="text-end">
            <Markdown className="heading-with-bottom-border"></Markdown>
            <Markdown>
                ## BENEFITS
            </Markdown>
          </Col>
          <Col id="sb4" className="space-back body-background-warning">
             <Markdown className="heading-with-bottom-border"></Markdown>

            <Markdown>{benefitList}</Markdown>
          </Col>

          <Col className="body-background-primary">
            <Markdown className="heading-with-bottom-border"></Markdown>

            <Markdown>{benefitList2}</Markdown>
          </Col>
        </Row>

        <Row className="extra-padding">
          <Link className="text-center header-nav" to={"/activities"}><Button>What activities are most important to you?</Button></Link>
        </Row>

      </Container>
    <Footer />
  </div>
  );
}

export default TreatmentOptions;
